import * as React from "react";

import { useGetAuditCountQuery } from "@/api/auditApi";
import { SubmissionCountBadge } from "@/components/Elements/SubmissionCountBadge/SubmissionCountBadge";
import { SubmissionMetWarning } from "@/components/Elements/SubmissionMetWarning/SubmissionMetWarning";

type PreviousSubmissionCountProps = {
  location?: string;
  startDate?: Date;
  endDate?: Date;
  auditTypeId: string;
  submissionsRequired: number;
  disableWarning?: boolean;
};

export const PreviousSubmissionCount = React.memo(
  ({
    location,
    startDate,
    endDate,
    auditTypeId,
    submissionsRequired,
    disableWarning = false,
  }: PreviousSubmissionCountProps) => {
    const [showWarning, setShowWarning] = React.useState(false);
    const auditCount = useGetAuditCountQuery(
      {
        auditTypeId,
        location,
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString(),
      },
      {
        skip:
          location === undefined || location === "" || startDate === undefined,
        refetchOnMountOrArgChange: true,
      },
    );

    React.useEffect(() => {
      if (
        (auditCount?.data ?? 0 >= submissionsRequired) &&
        !auditCount.isFetching &&
        !disableWarning
      ) {
        setShowWarning(true);
      }
    }, [submissionsRequired, auditCount, disableWarning]);

    return (
      <>
        <SubmissionCountBadge
          submittedAudits={auditCount?.data}
          submissionsRequired={submissionsRequired}
        />
        <SubmissionMetWarning
          isOpen={showWarning}
          onClose={() => setShowWarning(false)}
        />
      </>
    );
  },
);
