import { z } from "zod";

import { medRoomChecklistItem } from "../../../schemas";

export const ltssMedRoomStep1Schema = z.object({
  auditDate: z.date().max(new Date(), "Future date not allowed."),
  locationId: z.string().min(1, "Required"),
  reviewerId: z.string().min(1, "Required"),
  handHygienePosted: medRoomChecklistItem,
  handHygienePerformed: medRoomChecklistItem,
  firstAidKit: medRoomChecklistItem,
  ppePresent: medRoomChecklistItem,
  twoPersonIdentifiers: medRoomChecklistItem,
  medAdministrationObserved: medRoomChecklistItem,
});

export const ltssMedRoomStep2Schema = z.object({
  medRoomClean: medRoomChecklistItem,
  internalExternalSeperated: medRoomChecklistItem,
  medClosetTemp: medRoomChecklistItem,
  medClosetLight: medRoomChecklistItem,
  orderedMedSeperated: medRoomChecklistItem,
  stockMedLabeled: medRoomChecklistItem,
  orderedMedLabeled: medRoomChecklistItem,
  therapeuticLeaveMed: medRoomChecklistItem,
  medSupplies: medRoomChecklistItem,
  individualMedSeperated: medRoomChecklistItem,
  medLocked: medRoomChecklistItem,
  controlledSubstanceLog: medRoomChecklistItem,
  controlledSubstanceLocked: medRoomChecklistItem,
  controlledSubstanceLockedFridge: medRoomChecklistItem,
  expiredMed: medRoomChecklistItem,
});

export const ltssMedRoomStep3Schema = z.object({
  medDestructionArea: medRoomChecklistItem,
  emergencyMed: medRoomChecklistItem,
  medPrepared: medRoomChecklistItem,
  sharpsContainer: medRoomChecklistItem,
  foodStorage: medRoomChecklistItem,
  medRefrigeratorTemperature: medRoomChecklistItem,
  doNotUseAbbreviation: medRoomChecklistItem,
  lookAlikeSoundAlike: medRoomChecklistItem,
  highAlertMed: medRoomChecklistItem,
  cliaWaivedTest: medRoomChecklistItem,
  testSpecimenLabeled: medRoomChecklistItem,
  medCount: medRoomChecklistItem,
  medTrainedStaff: medRoomChecklistItem,
  waivedTestingControls: medRoomChecklistItem,
  prnMed: medRoomChecklistItem,
});

export const ltssMedRoomStep4Schema = z.object({
  ordersPrescribed: medRoomChecklistItem,
  ordersPrn: medRoomChecklistItem,
  medOrdersMatch: medRoomChecklistItem,
  marMedDetails: medRoomChecklistItem,
  marSpecialDirections: medRoomChecklistItem,
  drugFactSheet: medRoomChecklistItem,
  marCompleted: medRoomChecklistItem,
  marSignatures: medRoomChecklistItem,
  controlledDrugCount: medRoomChecklistItem,
  controlledDrugInvestigation: medRoomChecklistItem,
  hrcLrpConsent: medRoomChecklistItem,
  pharmaceuticalReview: medRoomChecklistItem,
  disposalForm: medRoomChecklistItem,
  medicationErrorForm: medRoomChecklistItem,
  medicationErrorReported: medRoomChecklistItem,
  medicationErrorFollowUp: medRoomChecklistItem,
  seizureReport: medRoomChecklistItem,
  seizureCumulativeReport: medRoomChecklistItem,
  weightsRecorded: medRoomChecklistItem,
  menstrualRecord: medRoomChecklistItem,
  menstrualRecordIssue: medRoomChecklistItem,
});
