import { z } from "zod";

export const medRoomChecklistItem = z
  .object({
    value: z.string().min(1, "Required"),
    followUp: z.string().optional(),
  })
  .refine((data) => !(data.value === "No" && data.followUp === ""), {
    message: "Follow up action is required",
    path: ["followUp"],
  });
