import { Badge } from "@chakra-ui/react";
import * as React from "react";

type SubmissionCountBadgeProps = {
  submittedAudits?: number;
  submissionsRequired: number;
};

export const SubmissionCountBadge = React.memo(
  ({ submittedAudits, submissionsRequired }: SubmissionCountBadgeProps) => {
    return (
      <Badge
        fontSize="1rem"
        colorScheme={
          submittedAudits === undefined
            ? "gray"
            : submittedAudits >= submissionsRequired
              ? "green"
              : "primary"
        }
      >{`${submittedAudits ?? "-"} / ${submissionsRequired} Submissions`}</Badge>
    );
  },
);
