import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "@/store";

import { LtssMedRoomChecklistFormData } from "../types";

type FormState = {
  data: Partial<LtssMedRoomChecklistFormData>;
  page: number;
};

const initialState: FormState = {
  data: {},
  page: 0,
};

export const ltssMedRoomFormSlice = createSlice({
  name: "ltssMedRoomForm",
  initialState,
  reducers: {
    setData: (
      state,
      action: PayloadAction<Partial<LtssMedRoomChecklistFormData>>,
    ) => {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },
    incrementPage: (state) => {
      state.page += 1;
    },
    decrementPage: (state) => {
      state.page -= 1;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    resetForm: () => initialState,
  },
});

export const { setData, incrementPage, decrementPage, setPage, resetForm } =
  ltssMedRoomFormSlice.actions;

export const selectPage = (state: RootState) => state.ltssMedRoomForm.page;
export const selectData = (state: RootState) => state.ltssMedRoomForm.data;

export const ltssMedRoomFormReducer = ltssMedRoomFormSlice.reducer;
