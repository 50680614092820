import camelcaseKeys from "camelcase-keys";

import { apiSlice } from "@/lib/rtkquery";
import { ApiResponse, EmployeeDTO } from "@/types";

type EmployeeResponse = ApiResponse<EmployeeDTO[]>;

const employeeApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getEmployees: build.query<EmployeeDTO[], void>({
      query: () => "api/v1/employees",
      transformResponse: (response: EmployeeResponse) =>
        camelcaseKeys(response.results),
    }),
  }),
});

export const { useGetEmployeesQuery } = employeeApi;
