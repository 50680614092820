import camelcaseKeys from "camelcase-keys";

import { apiSlice } from "@/lib/rtkquery";
import { ApiResponse, LocationDTO } from "@/types";

type LocationResponse = ApiResponse<LocationDTO[]>;

const locationApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getLocations: build.query<LocationDTO[], void>({
      query: () => "api/v1/locations",
      transformResponse: (response: LocationResponse) =>
        camelcaseKeys(response.results),
    }),
    getLocationsByAuditType: build.query<LocationDTO[], string>({
      query: (auditType) => `api/v1/locations/${auditType}`,
      transformResponse: (response: LocationResponse) =>
        camelcaseKeys(response.results),
    }),
  }),
});

export const { useGetLocationsQuery, useGetLocationsByAuditTypeQuery } =
  locationApi;
