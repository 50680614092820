import {
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

type SubmissionMetWarningProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const SubmissionMetWarning = ({
  isOpen,
  onClose,
}: SubmissionMetWarningProps) => {
  const navigate = useNavigate();

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Submissions Already Met</ModalHeader>
        <ModalCloseButton></ModalCloseButton>
        <Divider />
        <ModalBody>
          The number of submissions have already been met for this site for the
          current audit date.
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary" onClick={onClose} marginX="1rem">
            Continue
          </Button>
          <Button colorScheme="primary" onClick={() => navigate("/")}>
            Return Home
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
